import React from "react";
import UtilService from "../services/utilService";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import history from "../services/history";
import ApiService from "../services/apiService";
import ReactFlagsSelect from 'react-flags-select';

class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.utilService = new UtilService();
    this.apiService = new ApiService();
    this.state = {
      restaurant: {},
      loading: true,
      languages: [],
      languageLabels: '',
      defaultLanguage: ''
    }
    
  }

  componentDidMount() {


    if(this.props.match.params.id && this.props.match.params.id.length > 20) {
      console.log()
      this.id = this.props.match.params.id;
      let splitParameter;
      let parameter =  atob(decodeURIComponent(this.props.match.params.id));

      splitParameter = parameter.split('_');
      console.log("this.id", this.id)
      // let languages = this.utilService.getLocalStorageData('language');
      // if(languages) {
      //   this.restaurentParsed = this.utilService.getLocalStorageData('restaurentParsed');
      //   this.setState({
      //     languages: languages.list,
      //     languageLabels: languages.label,
      //     defaultLanguage: languages.default
      //   });
      //   this.onSelectFlag(languages.default);
      // } else {
      //   this.getDetails(splitParameter[1]);
      // }
      this.getDetails(splitParameter[1]);
    } else {
      this.setState({loading: false});
    }
  }

  async getDetails(id) {
      this.restaurentParsed = id;
      this.utilService.setLocalStorageData('restaurentParsed', this.restaurentParsed);
      this.apiService.get('languages/'+this.restaurentParsed).then(languageJson => {
        if(languageJson.languages && languageJson.languages.length) {
          let languagesList = [];
          let languageKeyLabels = {};
          languageJson.languages.map(object => {
            if(object.location === 'en') {
              object.location = 'us';
            }
            languagesList.push(object.location.toUpperCase());
            languageKeyLabels[object.location.toUpperCase()] = object.label;
            return true;
          });
          this.utilService.setLocalStorageData('language', languagesList);
          let defaultLanguage = languageJson.languages.filter(object => object.default === true);
          if(defaultLanguage.length) {
            let passedValue = (defaultLanguage[0].location === 'us')?'en': defaultLanguage[0].location;
            this.apiService.get(passedValue+'/'+this.restaurentParsed).then((response) => {
              this.setState({
                restaurant: response, 
                loading: false, 
                defaultLanguage:defaultLanguage[0].location.toUpperCase(), 
                languages: languagesList, 
                languageLabels: languageKeyLabels
              });
              this.utilService.setLocalStorageData('language', {
                list: languagesList,
                default: defaultLanguage[0].location.toUpperCase(),
                label: languageKeyLabels
              });
              this.id = this.props.match.params.id;
              this.utilService.setLocalStorageData('restaurantId', this.props.match.params.id);
              this.utilService.updateRestaurantData(response);
            }).catch((err) => {
              this.setState({loading: false});
              this.utilService.errorHandler(err);
            });
          }
        }
      }).catch((err) => {
        this.setState({loading: false});
        this.utilService.errorHandler(err);
      });
      
    
  }

  redirectToMenu = (event) => {
    event.preventDefault();
    history.push("/" + this.id + "/menu");
  };

  onSelectFlag = (countryCode) => {
      let passedValue = (countryCode.toLowerCase() === 'us')?'en': countryCode.toLowerCase();
      this.apiService.get(passedValue+'/'+this.restaurentParsed).then((response) => {
        this.setState({
          restaurant: response,
          defaultLanguage:countryCode.toUpperCase()
        });
        this.utilService.updateDefaultLanguage(countryCode.toUpperCase());
        this.utilService.updateRestaurantData(response);
      }).catch((err) => {
        this.utilService.errorHandler(err);
      });
  }

  render() {
    const {restaurant, loading, defaultLanguage, languages, languageLabels} = this.state;
    if(Object.keys(restaurant).length) {
      return (
        <>
        {defaultLanguage &&
                    <ReactFlagsSelect 
                    selectedSize={14}
                    className="menu-flags"
                    defaultCountry={defaultLanguage}
                      countries={languages} 
                      customLabels={languageLabels}
                      onSelect={this.onSelectFlag} />
          }
        <div className="welcome-background"
        style={{ backgroundImage: 'url("'+restaurant.urlInfo.restaurantImg + '/' + restaurant.restInfo.background_image+'")' }}>


          
          <div className="welcome-text-container">
              <div className="border-welcome">
                <img src={require("../assets/img/border.svg")} alt=""></img>
              </div>
              <div className="welcome-content">
                  <img className="rest-logo" src={restaurant.urlInfo.restaurantLogo + '/' + restaurant.restInfo.logo} 
                  alt={restaurant.restInfo.name}></img>
                  <h2>{restaurant.restInfo.name}</h2>
                  {/* <h5>Static - SANT AGUST I</h5> */}
                  <Link to="#" onClick={(e) => this.redirectToMenu(e)} className="btn btn-primary d-block">
                    View Menu
                  </Link>
              </div>
          </div>
        </div>
        </>
      );
      } else {
          return (!loading) ?
             <div className="d-flex align-items-center justify-content-center" style={{height:'100vh', width: '100%'}}>
              <h4>No Restaurant Found</h4>
            </div> 
            : ''
          
      }

    }
}
export default withRouter(Landing);
