import React from "react";
import { Switch, Route } from "react-router-dom";

import Landing from "../pages/index";
import Category from "../pages/category";
import CategoryDetail from "../pages/categoryDetail";
import ItemDetail from "../pages/itemDetail";
import NotFound from "../pages/notFound";

export default function Routes() {
  return (
    <Switch>

      <Route exact path={`${process.env.PUBLIC_URL}/:id`} component={Landing} />
      <Route path={`${process.env.PUBLIC_URL}/:restaurantId/menu`} component={Category} />
      <Route path={`${process.env.PUBLIC_URL}/category/:id`} component={CategoryDetail} />

      <Route path={`${process.env.PUBLIC_URL}/item/:id`} component={ItemDetail} />
      
      {/* redirect user to SignIn page if route does not exist and user is not authenticated */}
      {/* <Route path={`${process.env.PUBLIC_URL}/404`} component={NotFound} /> */}
      <Route component={NotFound} />
    </Switch>
  );
}
