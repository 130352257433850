import React from "react";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import history from "../services/history";
import UtilService from "../services/utilService";
const utilService = new UtilService();
class NotFound extends React.Component {

  goBack(event) {
    event.preventDefault();
    if(utilService.getLocalStorageData('restaurantId')) {
      history.push("/" + utilService.getLocalStorageData('restaurantId'));
    } else {
      history.push("/");
    }
  }

  render() {
    const id = utilService.getLocalStorageData('restaurantId');
    return (
      <div className="welcome-background"
      style={{ backgroundImage: 'url(' + require('../assets/img/welcome-background.jpg') + ')' }}>
        {id && 
          <Link to="#" onClick={(e) => this.goBack(e)} className="back-btn ml-4">
            <img src={require("../assets/img/back.svg")} alt=""></img>
          </Link>
        }
        <div className="welcome-text-container">
            <div className="border-welcome">
              <img src={require("../assets/img/border.svg")} alt=""></img>
            </div>
            <div className="welcome-content">
                <h2>404 - Not Found</h2>
            </div>
        </div>
      </div>
    );
  }
}
export default withRouter(NotFound);
