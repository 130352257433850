import React from "react";
import ApiService from "../services/apiService";
import UtilService from "../services/utilService";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import history from "../services/history";
import ReactFlagsSelect from 'react-flags-select';

class ItemDetail extends React.Component {
  constructor(props) {
    super(props);
    this.apiService = new ApiService();
    this.utilService = new UtilService();
    this.apiService = new ApiService();
    this.state = {
      restaurant: {},
      languages: [],
      languageLabels: '',
      defaultLanguage: ''
    }
  }

  componentDidMount() {
    let restaurant = this.utilService.getLocalStorageData("restaurant");
    if(!restaurant) {
      history.push("/")
    }

    let languages = this.utilService.getLocalStorageData('language');
    if(languages) {
      this.restaurentParsed = this.utilService.getLocalStorageData('restaurentParsed');
      this.setState({
        languages: languages.list,
        languageLabels: languages.label,
        defaultLanguage: languages.default,
        restaurant: restaurant
      })
    }

  }

  goBack(event) {
    event.preventDefault();
    let item = this.utilService.getLocalStorageData("item");
    history.push("/category/" + btoa(item.id));
  }

  onSelectFlag = (countryCode) => {
    let passedValue = (countryCode.toLowerCase() === 'us')?'en': countryCode.toLowerCase();
    this.apiService.get(passedValue+'/'+this.restaurentParsed).then((response) => {
      this.setState({
        restaurant: response,
        defaultLanguage:countryCode.toUpperCase()
      });
      this.utilService.updateDefaultLanguage(countryCode.toUpperCase());
      this.utilService.updateRestaurantData(response);
    }).catch((err) => {
      this.utilService.errorHandler(err);
    });
  }

  render() {
    const item = this.utilService.getLocalStorageData("item");
    const {restaurant, defaultLanguage, languages, languageLabels} = this.state;
    console.log("restaurant", restaurant)
    console.log("item", item)
    if(Object.keys(restaurant).length) {
    return (
      <>
      {defaultLanguage &&
                  <ReactFlagsSelect 
                  selectedSize={14}
                  className="menu-flags"
                  defaultCountry={defaultLanguage}
                    countries={languages} 
                    customLabels={languageLabels}
                    onSelect={this.onSelectFlag} />
        }
      <div className="item-screen">
        <div className="back-btn">
          <Link to="#" onClick={(e) => this.goBack(e)}>
            <img src={require("../assets/img/back-dark.svg")} alt=""></img>
          </Link>
        </div>
        <div className="item-image">
            <img src={restaurant.urlInfo.menuImg + '/' + item.image} alt=""></img>
        </div>
        <div className="item-details">
            <h2>{item.name}</h2>
            {item.price > 0 && 
                    <h4 className="price">
                      <CurrencyFormat fixedDecimalScale= {true} decimalScale={2}
                        value={item.price} displayType={"text"} thousandSeparator={true}
                        prefix={(item.currency_symbol)?item.currency_symbol:"$"} />
                    </h4>
            }
            {item && item.item_ingredient && item.item_ingredient.length > 0 && 
            <div className="ingredients-list-container">
                {/* <h4>Ingredients</h4> */}
                <div className="ingredients-list">
                
                  {item.item_ingredient.map((object, index) => {
                    return (object.ingredient) ?
                      <div key={index} className="ingredients-item">
                          {object.ingredient.image &&
                          <img src={restaurant.urlInfo.ingredientImg + '/' + object.ingredient.image} alt=""></img>
                          }
                          {object.ingredient.name &&
                            <p>{object.ingredient.name}</p>
                          }
                      </div>
                      : ''
                  })
                  }
                </div>
            </div>
          
            }
            <div className="details-item">
                {/* <h4>What is combined with</h4> */}
                <p>{item.description}</p>
            </div>
        </div>
    </div>
    </>
    );
          } else {
                return '';
          }
  }
}
export default withRouter(ItemDetail);
