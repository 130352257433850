import React from 'react';
import { Router } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "toastr/build/toastr.min.css"
import 'react-flags-select/css/react-flags-select.css';
import './App.css';
import Routes from "./routes";
import history from "./services/history";
import ReactGA from 'react-ga';
ReactGA.initialize('G-TZ3G6NZK36');
ReactGA.pageview(window.location.pathname + window.location.search);
function App() {
  return (
    <Router basename={"/"} history={history}>
      <Routes />
    </Router>
  );
}

export default App;
