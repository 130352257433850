import React from "react";
import UtilService from "../services/utilService";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import btoa from "btoa";
import history from "../services/history";
import ReactFlagsSelect from 'react-flags-select';
import ApiService from "../services/apiService";

class Category extends React.Component {
  constructor(props) {
    super(props);
    this.utilService = new UtilService();
    this.apiService = new ApiService();
    this.state = {
      restaurant: {},
      languages: [],
      languageLabels: '',
      defaultLanguage: ''
    }
  }

  componentDidMount() {
    let restaurant = this.utilService.getLocalStorageData("restaurant");
    if(!restaurant) {
      history.push("/")
    }

    let languages = this.utilService.getLocalStorageData('language');
    if(languages) {
      this.restaurentParsed = this.utilService.getLocalStorageData('restaurentParsed');
      this.setState({
        languages: languages.list,
        languageLabels: languages.label,
        defaultLanguage: languages.default,
        restaurant: restaurant
      })
    }

  }

  redirectToDetail = (event, category) => {
    event.preventDefault();
    this.utilService.setLocalStorageData("category", category);
    history.push("/category/" + btoa(category.id));
  };

  goBack(event) {
    event.preventDefault();
    let id = this.props.match.params.restaurantId;
    history.push("/" + id);
  }

  onSelectFlag = (countryCode) => {
    let passedValue = (countryCode.toLowerCase() === 'us')?'en': countryCode.toLowerCase();
    this.apiService.get(passedValue+'/'+this.restaurentParsed).then((response) => {
      this.setState({
        restaurant: response,
        defaultLanguage:countryCode.toUpperCase()
      });
      this.utilService.updateDefaultLanguage(countryCode.toUpperCase());
      this.utilService.updateRestaurantData(response);
    }).catch((err) => {
      this.utilService.errorHandler(err);
    });
  }

  render() {

    const {restaurant, defaultLanguage, languages, languageLabels} = this.state;
    if(Object.keys(restaurant).length) {
    return (
      <>
                  {defaultLanguage &&
                    <ReactFlagsSelect 
                    selectedSize={14}
                    className="menu-flags"
                    defaultCountry={defaultLanguage}
                      countries={languages} 
                      customLabels={languageLabels}
                      onSelect={this.onSelectFlag} />
            }
        <div className="menu-header" 
        style={{ backgroundImage: 'url("'+ restaurant.urlInfo.restaurantImg + '/' + restaurant.restInfo.background_image + '")' }}>
            <Link to="#" onClick={(e) => this.goBack(e)} className="back-btn">
              <img src={require("../assets/img/back.svg")} alt=""></img>
            </Link>
            <div className="text-container">
                <h3>Welcome to</h3>
                <h4>{restaurant.restInfo.name}</h4>
                {/* <p>Static - A traditional lbizan house in the heart of Sant Agusti des Vedra</p> */}
            </div>
        </div>
        <div className="menu-list">
           {restaurant && restaurant.categoryInfo && restaurant.categoryInfo.length > 0 && 
              restaurant.categoryInfo.map((object) => {
                return (
                <div key={object.id} className="menu-item" onClick={(e) => this.redirectToDetail(e, object)}
                style={{ backgroundImage: 'url(' + restaurant.urlInfo.categoryImg + '/' + object.image + ')', cursor: 'pointer' }}>
                  <Link to="#">
                    <div className="menu-text">
                        <h4>{object.name}</h4>
                        {/* <div className="time">
                            <div className="time-text">(till 13:00)</div>
                        </div> */}
                    </div>
                  </Link>
                </div>
                )
              })
           }
        </div>
      </>
    );
    } else {
      return '';
    }
  }
}
export default withRouter(Category);
