import toastr from "toastr";
export default class UtilService {
  authToken;
  isShowMsg;
  commonErrorMsg;
  propertyDetail;

  constructor() {
    this.commonErrorMsg = "Something went wrong. Please try again later";
    this.propertyDetail = {};
  }

  /* = 
     = get local storage token
    ----------------------------------- */
  getToken() {
    if (this.authToken) {
      return this.authToken;
    } else {
      return localStorage.getItem("token")
        ? localStorage.getItem("token")
        : null;
    }
  }

  /* = 
     = set token in localstorage
    ----------------------------------- */
  setToken(token) {
    this.authToken = token;
    localStorage.setItem("token", token);
  }

  /* = 
     = clear localstorage
    ----------------------------------- */
  clearLocalStorage() {
    this.authToken = "";
    localStorage.clear();
  }

  /* =
     = remove localstorage key
    ----------------------------------- */
  removeLocalStorageData(key) {
    localStorage.removeItem(key);
  }

  /* =
     = set localstorage with key and value
    ------------------------------------------ */
  setLocalStorageData(key, value) {
    const enc = JSON.stringify(value);
    localStorage.setItem(key, window.btoa(unescape(encodeURIComponent(enc))));
  }

  /* =
     = Get the localstorage data using key
    ------------------------------------------ */
  getLocalStorageData(key) {
    if (localStorage.getItem(key)) {
      const dec = decodeURIComponent(
        escape(window.atob(localStorage.getItem(key)))
      );
      return JSON.parse(dec);
    } else {
      return null;
    }
  }

  /* =
     = Show error message
    ------------------------------------------ */
  showError(title, message) {
    toastr.error(title, message);
  }

  /* =
     = Show success message
    ------------------------------------------ */
  showSuccess(title, message) {
    toastr.success(title, message);
  }

  /* =
     = common error handler
    ------------------------------------------ */
  errorHandler(error) {
    return "";
    // let title = "";
    // if (error && error.hasOwnProperty("message")) {
    //   if (typeof error.message === "object") {
    //     for (const x of Object.keys(error.message)) {
    //       this.showError(title, error.message[x]);
    //     }
    //   } else if (typeof error.message === "string") {
    //     this.showError(title, error.message);
    //   }
    // } else {
    //   this.commonErrorMsg = "Something went wrong. Please try again later";
    //   this.showError(title, this.commonErrorMsg);
    // }
  }

  /* =
     = manage user data
    ------------------------------------------ */
  changeUserProfileData(data) {
    this.setLocalStorageData("user_data", data);
  }

  updateRestaurantData(data) {
    this.setLocalStorageData("restaurant", data);
  }

  updateDefaultLanguage(language) {
    let languages = this.getLocalStorageData("language");
    if(languages && languages.default) {
      languages.default = language;
      this.setLocalStorageData("language", languages);
    }
    return true;
  }

  /* =
     = return if user logged in or not
    ------------------------------------------ */
  isLoggedIn() {
    return localStorage.getItem("token") ? true : false;
  }





}
