import axios from "axios";
import UtilService from "./utilService";

axios.interceptors.request.use(
  function (config) {
    // spinning start to show
    // UPDATE: Add this code to show global loading indicator
    document.body.classList.add("loading-indicator");
    let service = new UtilService();
    if (service.isLoggedIn()) {
      config.headers.Authorization =
        service.getLocalStorageData("token_type") + " " + service.getToken();
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    document.body.classList.remove("loading-indicator");
    return response;
  },
  function (error) {
    document.body.classList.remove("loading-indicator");
    return Promise.reject(error);
  }
);

export default class ApiService {
  constructor() {
    this.utilService = new UtilService();
  }

  /* =
     = get single object
    ----------------------------------- */
  async get(endpoint) {
    return new Promise((resolve, reject) => {
        let axiosOptions = {
          method: "get",
          url: process.env.REACT_APP_baseURL + endpoint + `.json?timestamp=${new Date().getTime()}`,
        };
        axios(axiosOptions)
          .then((res) => {
            resolve(res.data);
          })
          .catch((error) => {
            if (error.response) {
              if (error.response.data) {
                reject(error.response.data);
              } else {
                reject(error.response);
              }
            } else {
              reject(error);
            }
          });
    });
  }
}
