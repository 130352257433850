import React from "react";
import UtilService from "../services/utilService";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import CurrencyFormat from "react-currency-format";
import history from "../services/history";
import ReactFlagsSelect from 'react-flags-select';
import ApiService from "../services/apiService";

class CategoryDetail extends React.Component {
  constructor(props) {
    super(props);
    this.utilService = new UtilService();
    this.apiService = new ApiService();
    this.state = {
      restaurant: {},
      languages: [],
      languageLabels: '',
      defaultLanguage: ''
    }
  }

  componentDidMount() {
    let restaurant = this.utilService.getLocalStorageData("restaurant");
    if(!restaurant) {
      history.push("/")
    }
    let languages = this.utilService.getLocalStorageData('language');
    if(languages) {
      this.restaurentParsed = this.utilService.getLocalStorageData('restaurentParsed');
      this.setState({
        languages: languages.list,
        languageLabels: languages.label,
        defaultLanguage: languages.default,
        restaurant: restaurant
      })
    }

  }

  redirectToDetail = (event, item) => {
    event.preventDefault();
    this.utilService.setLocalStorageData("item", item);
    history.push("/item/" + btoa(item.id));
  };

  goBack(event) {
    event.preventDefault();
    let id = this.utilService.getLocalStorageData('restaurantId');
    history.push("/" + id + "/menu");
  }

  onSelectFlag = (countryCode) => {
    let passedValue = (countryCode.toLowerCase() === 'us')?'en': countryCode.toLowerCase();
    this.apiService.get(passedValue+'/'+this.restaurentParsed).then((response) => {
      this.setState({
        restaurant: response,
        defaultLanguage:countryCode.toUpperCase()
      });
      this.utilService.updateDefaultLanguage(countryCode.toUpperCase());
      this.utilService.updateRestaurantData(response);
    }).catch((err) => {
      this.utilService.errorHandler(err);
    });
  }

  render() {
    const category = this.utilService.getLocalStorageData("category");
    const {restaurant, defaultLanguage, languages, languageLabels} = this.state;

    console.log("restaurant", restaurant)
    if(Object.keys(restaurant).length) {
    return (
      <>
        {(category && category.menu_card && category.menu_card.length > 0) &&defaultLanguage &&
                    <ReactFlagsSelect 
                    selectedSize={14}
                    className="menu-flags"
                    defaultCountry={defaultLanguage}
                      countries={languages} 
                      customLabels={languageLabels}
                      onSelect={this.onSelectFlag} />
        }
      <div className="top-header">
        <Link to='#' onClick={(e) => this.goBack(e)}>
          <img src={require("../assets/img/back-dark.svg")} alt=""></img>
        </Link>
        <span>{category.name}</span>
      </div>
      <div className="category-list">
          {(category && category.menu_card && category.menu_card.length > 0) ?
              <> 
              {category.menu_card.map((object) => {
                return (
                  <div className="category-item" key={object.id} onClick={(e) => this.redirectToDetail(e, object)}
                    style={{cursor:'pointer'}}>
                      <div className="category-img">
                        <Link to="#">
                          <img src={restaurant.urlInfo.menuImg + '/' + object.image} alt=""></img>
                        </Link>
                      </div>
                      <div className="category-details">
                          <h4>{object.name}</h4>
                          {object.price > 0 && 
                            <h5>
                              <CurrencyFormat
                                fixedDecimalScale= {true}
                                decimalScale={2}
                                value={object.price}
                                displayType={"text"}
                                thousandSeparator={true}
                                prefix={(object.currency_symbol)?object.currency_symbol:"$"}
                              />
                            </h5>
                          }
                      </div>
                  </div>
                )
              })
           }
           </>
           : (
            <div className="d-flex align-items-center justify-content-center" style={{height:'100vh', width: '100%'}}>
              <h4>No Items</h4>
            </div> 
           )
          }   
        </div>
      </>
    );
        } else {
          return ''
        }
  }
}
export default withRouter(CategoryDetail);
